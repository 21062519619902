import { useAuth } from "react-oidc-context"
import { ComparisonAPI, DataAPI, SampleAPI } from "../api/DataAPI"
import { useDataViewerCtrStore } from "../stores/DataViewerCtrStore"
import { View, ViewDTO } from "../types/ViewTypes"
import { useAppStore } from "../stores/AppStore"
import { useComparisonStore } from "../stores/ComparisonStore"
import { useSampleInfiniteQuery } from "./useSampleInfiniteQuery"
import { Toaster } from "../utils/Toaster"
import { useSampleDialogStore } from "../stores/SampleDialogStore"
import { Sample, SampleMetadata, SearchResponse } from "../types/SampleTypes"
import { ComparedSample, Comparison, ComparisonWithAnalysis } from "../types/ComparisonTypes"
import { useOpenComparisonsStore } from "../stores/OpenComparisonsStore"
import { useOpenComparisonDrawerStore } from "../stores/OpenComparisonDrawerStore"
import { useOrganismStore } from "../stores/OrganismStore"
import { UseInfiniteQueryResult } from "@tanstack/react-query"
import { Outbreak } from "../types/OutbreakTypes"
import { useOrganizationStore } from "../stores/OrganizationStore"
import { isMatch } from "../components/OpenComparisonToolBar"
import { useCallback, useMemo } from "react"
import { OutbreakAPI } from "../api/DataAPI"

var merge = require('lodash.merge');
var dot = require('dot-object');

interface DataViewerCtrFunctions {
  getViewInformation: (view?: View, viewId?: string) => void
  loadView: (viewId: string) => Promise<void>
  selectSample: (sampleId: string) => void
  sampleData: Sample[]
  outbreakData: Outbreak[]
  selectComparison: (selectedComparison: Comparison, addedComparedSamples?: ComparedSample[], unsaved?: boolean) => Promise<void>
  loadUPGMAData: (comparison: Comparison, tabId: string) => Promise<void>
  loadComparisonData: (comparison: Comparison, analysesDisplayed: string, tabId: string) => Promise<void>
  sampleInfiniteQuery: UseInfiniteQueryResult<SearchResponse, unknown>
  saveCellEdits: () => Promise<void>
  resetView: () => void
}

export const useDataViewerCtrFunctions = (): DataViewerCtrFunctions => {
  const { setViewLoading, setSortedColumn, sort, setUnselectafterdelete, setErrorMessage, cellEdits, setCellEdits, setNewEdits, setColumnFilters } = useDataViewerCtrStore()
  const { setSelectedView, selectedView, setIsOutbreakView, setSelectedViewId } = useAppStore()
  const { setComparisons } = useComparisonStore()
  const { tabs, selectedTabId, addTab, getTabById, setTabAnalysesDetailError, setTabUpgmaError, setSelectedTabId,
    setTabSampleDetailError, setTabChartLoading, setTabSamplesLoading, setTabAnalysesLoading, setTabSampleData,
    setTabAnalysesData, setTabComparison, removeAllTagData, setAllProcessTypes } = useOpenComparisonsStore()
  const { openSampleDialog } = useSampleDialogStore()
  const { setShowOpenComparisonDrawer } = useOpenComparisonDrawerStore()
  const { organism } = useOrganismStore()
  const { organization } = useOrganizationStore()
  const auth = useAuth()
  const sampleInfiniteQuery = useSampleInfiniteQuery(selectedView, sort.field, cellEdits);
  const sampleAPI = new SampleAPI(auth.user?.access_token ?? "")
  const outbreakAPI = new OutbreakAPI(auth.user?.access_token ?? "")
  const viewAPI = new DataAPI<View, ViewDTO>("views", auth.user?.access_token ?? "")
  const comparisonAPI = new ComparisonAPI(auth.user?.access_token ?? "")

  const getViewInformation = async (view?: View, viewId?: string) => {
    if (!viewId && !view) {
      throw new Error("No view information present")
    }
    if (!view) {
      view = await viewAPI.get(viewId!)
    }
    view.columns = view.columns.map(v => ({
      ...v,
      path: organism?.properties[v.name]?.path ?? v.path
    }))

    const sortedColumn = view.columns.filter(v => v.sorted)[0]
    if (sortedColumn)
      setSortedColumn({
        sortedColumnName: sortedColumn.name,
        sortedColumnIndex: view.columns.indexOf(sortedColumn),
        sortedColumnAscending: sortedColumn.sorted === "asc",
        sortedColumnPath: sortedColumn.path
      })


    setSelectedView(view)

    setIsOutbreakView(view.isOutbreak === undefined ? false : view.isOutbreak)
    setUnselectafterdelete(false);
  }

  const getComparisons = async () => {
    const comnparisons = await comparisonAPI.getComparisonsByOrganism(organism!.name)
    setComparisons(comnparisons)
  }
  const loadView = async (viewId: string) => {
    try {
      resetView()
      setViewLoading(true)
      setSelectedViewId(viewId)
      let view = undefined;
      if (viewId === "temp") {
        view = selectedView
      }
      await Promise.all([getViewInformation(view, viewId), getComparisons(),])
      //Set view column paths to updated paths in field. *TODO: remove path from ViewColumn interface

      setErrorMessage("");
    }
    catch (e: any) {
      setErrorMessage(`There was an error loading the view: ${e.message}`)
      Toaster.show({ icon: "error", message: `There was an Error loading the View. Check the console for more details.`, intent: "danger" })
    }
    setViewLoading(false)
  }
  const selectSample = useCallback((sampleId: string) => {
    // setShowSampleDialog(true, sampleId)
    openSampleDialog(sampleId, !!selectedView?.nationalDatabase, auth.user?.access_token ?? "")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView?.nationalDatabase, auth.user?.access_token])

  const sampleData = useMemo(() => {
    // store unique IDs to prevent duplicates
    const uniqueIds = new Set();
    return sampleInfiniteQuery.data?.pages.reduce((pv, cv, index) => {
      const newItems = cv.items.filter(item => {
        if (uniqueIds.has(item.id))
          return false
        else {
          uniqueIds.add(item.id)
          return true
        }
      })
      return [...pv, ...newItems];
    }, [] as Sample[]) ?? []
  }, [sampleInfiniteQuery.data])

  const outbreakData = useMemo(() => {
    // store unique IDs to prevent duplicates
    const uniqueIds = new Set();
    return sampleInfiniteQuery.data?.pages.reduce((pv, cv, index) => {
      const newItems = cv.items.filter(item => {
        if (uniqueIds.has(item.id))
          return false
        else {
          uniqueIds.add(item.id)
          return true
        }
      })
      return [...pv, ...newItems];
    }, [] as Outbreak[]) ?? []
  }, [sampleInfiniteQuery.data])

  const getComparedDataList = async (comparedSamples: ComparedSample[], analysesName: string) => {
    // var comparedDataList = [] as ComparedSampleAnalysis[]
    const comparedSampleAnalyses = await sampleAPI.GetAllAnalysesByComparedSamples(comparedSamples)
    setAllProcessTypes([...new Set(comparedSampleAnalyses.map(v => v.processName ?? "").filter(p => p))])
    const updatedComparedSampleAnalyses = comparedSampleAnalyses.map(v => ({
      ...v,
      analysisData: {
        "region": v.processName,
        ...v.analysisData
      }
    }))
    return {
      processName: analysesName,
      comparedData: updatedComparedSampleAnalyses.filter(v => isMatch(analysesName, v.processName ?? ""))
    } as ComparisonWithAnalysis

  }

  const loadComparisonData = async (comparison: Comparison, analysesDisplayed: string, tabId: string) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const { comparedSamples } = comparison
    setTabSampleDetailError(tabId, "")
    setTabSamplesLoading(tabId, true)
    setTabAnalysesLoading(tabId, true)
    try {//Load Samples from View. Fetch samples not found in view
      const viewSamples = sampleData.filter(v => comparedSamples.map(v => v.sampleId).includes(v.id));
      const missingSampleIds = comparedSamples.filter(v => !viewSamples.map(v => v.id).includes(v.sampleId))
      const loadedLabSamples = await sampleAPI.queryById(missingSampleIds.filter(v => !v.nationalDatabase).map(v => v.sampleId), organization?.organizationName ?? "", 0, 0, signal)
      const loadedNDBSamples = await sampleAPI.queryById_ndb(missingSampleIds.filter(v => v.nationalDatabase).map(v => v.sampleId), 0, 0, signal)
      if (getTabById(tabId))
        setTabSampleData(tabId, [...viewSamples, ...loadedLabSamples, ...loadedNDBSamples])
    }
    catch (e: any) {
      if (getTabById(tabId)) {
        setTabSampleDetailError(tabId, `There was an error loading the sample metadata: ${e.message}`)
        Toaster.show({ icon: "error", message: `There was an error loading the sample metadata.`, intent: "danger" })
      }
    }
    setTabSamplesLoading(tabId, false)

    try {//Load Analyses Data
      const analysesData = organization?.organizationName === "CaliciNet"
        ? await getComparedDataList(comparedSamples, analysesDisplayed)
        : await sampleAPI.GetAnalysesByComparedSamples(comparedSamples, analysesDisplayed);
      if (!analysesData)
        throw (new Error("No analyses data could not be found."))
      if (getTabById(tabId))
        setTabAnalysesData(tabId, analysesData)
    }
    catch (e: any) {
      if (getTabById(tabId)) {
        setTabAnalysesDetailError(tabId, `There was an error loading the analyses data: ${e.message}`)
        Toaster.show({ icon: "error", message: `There was an error loading the analyses data.`, intent: "danger" })
      }
    }
    if (getTabById(tabId))
      setTabAnalysesLoading(tabId, false)
  }

  const loadUPGMAData = async (comparison: Comparison, tabId: string) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const currentTab = getTabById(tabId);
    if (currentTab) {
      setTabUpgmaError(tabId, "")
      setTabChartLoading(tabId, true)
      try {
        const { comparedSamples, dendrogramAlgorythm, dendrogramDistance } = comparison
        //when switching between schemes, allele difference labels are removed
        removeAllTagData(dendrogramDistance, dendrogramAlgorythm)
        const textUpgma_treeResult = await sampleAPI.GetUpgmaTreeByComparedSamples(comparedSamples, dendrogramAlgorythm, dendrogramDistance, organism?.name, comparison.mlstScheme, signal);
        const upgma_treeResult = JSON.parse(textUpgma_treeResult)
        if (!upgma_treeResult['distance matrix'])
          throw (new Error("Error parsing data from UPGMA result: Distance matrix missing."))
        if (!upgma_treeResult['leaf order'])
          throw (new Error("Error parsing data from UPGMA result: Leaf order missing."))
        if (!upgma_treeResult.tree)
          throw (new Error("Error parsing data from UPGMA result: Newick file missing."))

        //Set tab by index incase selected tab changed. Verify tab did not close.
        if (currentTab)
          setTabComparison(tabId, {
            ...comparison,
            distanceMatrix: upgma_treeResult['distance matrix'],
            leafOrder: upgma_treeResult['leaf order'],
            newick: upgma_treeResult.tree
          })
      }
      catch (e: any) {
        if (currentTab) {
          setTabUpgmaError(tabId, `There was an error loading the UPGMA tree data: ${e.message}`)
          Toaster.show({ icon: "error", message: `There was an error loading the UPGMA tree data.`, intent: "danger" })
        }
      }
      if (currentTab)
        setTabChartLoading(tabId, false)
    }
  }

  const selectComparison = async (selectedComparison: Comparison, addedComparedSamples?: ComparedSample[], unsaved?: boolean) => {
    const comparison = {
      ...selectedComparison,
      //merge added comparedsamples with existing
      comparedSamples: addedComparedSamples ? [
        ...selectedComparison.comparedSamples ?? [],
        ...addedComparedSamples
      ] : selectedComparison.comparedSamples,
      newick: (addedComparedSamples ?? []).length ? undefined : selectedComparison.newick,
      distanceMatrix: (addedComparedSamples ?? []).length ? undefined : selectedComparison.distanceMatrix,
      leafOrder: (addedComparedSamples ?? []).length ? undefined : selectedComparison.leafOrder
    }
    const existingTab = selectedComparison.id ? tabs.find(v => v.comparison.id === selectedComparison.id) : null
    let newId = undefined
    if (existingTab?.comparison?.id)
      setSelectedTabId(existingTab.id)
    else
      newId = addTab(comparison, organization?.organizationName, !!addedComparedSamples || unsaved)
    setShowOpenComparisonDrawer(true)

    //load comparison data if samples added or new tab created
    if (addedComparedSamples || !existingTab)
      await loadComparisonData(comparison, organization?.organizationName === "CaliciNet" ? "SEQ_RegB-GI" : "quality", newId ?? selectedTabId)

    //load chart data if samples added or newick not found
    if (!(!addedComparedSamples && comparison.newick && comparison.distanceMatrix && comparison.leafOrder) && organization?.organizationName !== 'CaliciNet')
      await loadUPGMAData(comparison, newId ?? selectedTabId)
  }

  const saveCellEdits = async () => {
    try {
      const samples = sampleInfiniteQuery.data?.pages.reduce((pv, cv) => {
        return [...pv, ...cv.items]
      }, [] as Sample[]) ?? []
      const cellEditArray = Object.entries(cellEdits).map(v => v[1])
      if (cellEditArray.filter(v => v.error).length > 0 && !window.confirm("Some changes are invalid and will not take effect. Would you like to continue saving?"))
        return

      setViewLoading(true)
      const metadataArray = cellEditArray.filter(v => !v.error).map(v => ({ sampleId: samples[v.rowIndex].id, metadata: { [v.path.replace("data.metadata.", "")]: v.value } })).reduce((pv, cv) => {
        if (pv.map(v => v.sampleId).includes(cv.sampleId))
          pv[pv.map(v => v.sampleId).indexOf(cv.sampleId)].metadata = { ...pv[pv.map(v => v.sampleId).indexOf(cv.sampleId)].metadata, ...cv.metadata }
        else
          pv.push(cv)
        return pv
      }, [] as { sampleId: string, metadata: SampleMetadata }[])
      const sampleEditArray = cellEditArray
        .reduce((pv, cv) => {
          if (cv.error)
            return pv
          const index = pv.map(v => v.rowIndex).indexOf(cv.rowIndex)
          if (index > -1)
            pv[index].sample[cv.path] = cv.value
          else
            pv = [...pv, { path: cv.path, rowIndex: cv.rowIndex, sample: { [cv.path]: cv.value } }]
          return pv
        }, [] as { path: string, rowIndex: number, sample: { [key: string]: any } }[])
        .map(v => (merge(samples[v.rowIndex], dot.object(v.sample))))
      if (selectedView?.nationalDatabase)
        await Promise.all(metadataArray.map(v => {
          selectedView?.isOutbreak ? outbreakAPI.updateNDBMetadata(v.sampleId, v.metadata) : sampleAPI.updateNDBMetadata(v.sampleId, v.metadata)
        }))
      else
        await Promise.all(sampleEditArray.map(v => sampleAPI.update(v.id, v)))
    }
    catch (e: any) {
      Toaster.show({ icon: "error", message: `There was an error saving cell edits. Check the console for more details.`, intent: "danger" })
    }

    setCellEdits({})
    setNewEdits([])
    //update sample data in the lab or ndb view 
    sampleInfiniteQuery.remove();
    await sampleInfiniteQuery.refetch()
    setViewLoading(false)
  }

  const resetView = () => {
    setSelectedView(undefined);
    sampleInfiniteQuery.remove();
    setViewLoading(false);
    setCellEdits({});
    setNewEdits([]);
    setSortedColumn(undefined);
    // setSelectedSampleIds([]);
    setUnselectafterdelete(false)
    setErrorMessage("");
    setColumnFilters({})
  }

  return { getViewInformation, loadView, selectSample, sampleData, outbreakData, selectComparison, loadUPGMAData, loadComparisonData, sampleInfiniteQuery, saveCellEdits, resetView }
}